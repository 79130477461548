import { render, staticRenderFns } from "./RespirationMeasurement.vue?vue&type=template&id=2ff5ca76&scoped=true&"
import script from "./RespirationMeasurement.vue?vue&type=script&lang=js&"
export * from "./RespirationMeasurement.vue?vue&type=script&lang=js&"
import style0 from "./RespirationMeasurement.vue?vue&type=style&index=0&id=2ff5ca76&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff5ca76",
  null
  
)

export default component.exports